<template>
  <section-loop :page="response" />
</template>

<script>
import SectionLoop from '~/components/base/SectionLoop'
import Head from '~/mixins/Head'
export default {
  name: 'Page',
  transition: 'page',
  components: {
    SectionLoop
  },
  mixins: [Head],
  async asyncData({ app, $axios, params, store }) {
    const lang = app.i18n.locale
    const page = await $axios.get(
      `${process.env.FRONTEND_URL}/_api/page/home`,
      {
        params: { lang }
      }
    )
    page.data = await store.dispatch('site/transformSections', page.data)
    return { response: page.data }
  }
}
</script>

<style lang="scss" scoped></style>
